<template>
  <div class="profile">
    <HeroContainer back="/">
      <img src="@/assets/images/user-avatar.svg" alt="">
    </HeroContainer>
    <b-container>
      <b-row>
        <b-col cols="12" class="pt-4 text-center">
          <h3>Account</h3>
          <h2 v-if="user.user_display_name">{{ user.user_display_name }}</h2>
          <h4 v-if="user.user_email" class="mb-4">{{ user.user_email }}</h4>
          <b-button variant="primary" size="lg" @click="logout" class="text-white btn-maxwidth mb-4" block>Logout</b-button>
          <b-button variant="link" to="/" class="btn-back d-inline-flex flex-row align-items-center"><b-icon icon="arrow-left-circle" class="mr-1"></b-icon> Back</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeroContainer from '@/components/HeroContainer'

export default {
  components: {
    HeroContainer
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$store.commit('clear_user')
        this.$router.push('/login')
      })
    }
  },
}
</script>

<style lang="scss">
  .btn-maxwidth {
    max-width:250px;
    margin-left:auto;
    margin-right:auto;
  }

  .btn-back {
    &:hover {
      text-decoration:none;
    }
  }
</style>